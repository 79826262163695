@import './variable';

.bg-primary {
    background-color: $primary-color;
}

.font-default {
    font-family: $default-font;
}

.box-modal-style {
    position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 400px;
	background-color: white;
	border: 2px solid #0348FD;
	box-shadow: 24px;
	padding: 16px;
}

.ql-container {
	flex: 1 !important;
	display: flex !important;
	flex-direction: column !important;
	overflow: auto !important;
}

.ql-editor {
	height: 100% !important;
	flex: 1 !important;
}

.content-wrapper.ql-container {
	height: auto !important;
	flex: none !important;
}