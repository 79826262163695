.content-wrapper {
    &.ql-editor {
        height: auto !important;
        flex: none !important;
    }
    // a {
    //     color: #216fdb;
    //     &:hover {
    //         text-decoration: underline #216fdb;
    //     }
        
    // }

    // h1 {
    //     font-size: 2rem;
    // }

    // h2 {
    //     font-size: 1.5rem;
    // }
}